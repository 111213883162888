import React from 'react'
import { useTranslation } from 'react-i18next'

const TermsOfUse = () => {
    const { t } = useTranslation('terms')
    return (
        <div className="termsPage">
            <h1 className="pageTitle">{t('header')}</h1>
            <ol>
                <h2>
                    <li>{t('services.title')}</li>
                </h2>
                <p>{t('services.following')}</p>
                <p>{t('services.service')}</p>
                <p>{t('services.entering')}</p>
                <p className="bold">{t('services.read')}</p>
                <p>{t('services.disagree')}</p>

                <h2>
                    <li>{t('account.title')}</li>
                </h2>
                <p>{t('account.illegal')}</p>
                <p>{t('account.warrant')}</p>
                <p>{t('account.remove')}</p>

                <h2>
                    <li>{t('communication.title')}</li>
                </h2>
                <p>{t('communication.using')}</p>
                <p>{t('communication.interactive')}</p>

                <h2>
                    <li>{t('content.title')}</li>
                </h2>
                <p>{t('content.make')}</p>
                <ol type="A">
                    <p>
                        <li>{t('content.available.description')}</li>
                    </p>
                    <ol type="a">
                        <p>
                            <li>{t('content.available.downloading')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.employer')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.complied')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.virus')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.spam')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.porn')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.ad')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.named')}</li>
                        </p>
                        <p>
                            <li>{t('content.available.code')}</li>
                        </p>
                    </ol>
                    <p>
                        <li>{t('content.limiting.description')}</li>
                    </p>
                    <ol type="a">
                        <p>
                            <li>{t('content.limiting.refuse')}</li>
                        </p>
                        <p>
                            <li>{t('content.limiting.terminate')}</li>
                        </p>
                    </ol>
                </ol>
                <p>{t('content.user')}</p>

                <h2>
                    <li>{t('fees.title')}</li>
                </h2>
                <p>{t('fees.pay')}</p>
                <h4>{t('fees.payments.title')}</h4>
                <p>{t('fees.payments.method')}</p>
                <p>{t('fees.payments.credit')}</p>
                <p>{t('fees.payments.providing')}</p>
                <h4>{t('fees.refunds.title')}</h4>
                <p>{t('fees.refunds.description')}</p>
                <h4>{t('fees.subscriptions.title')}</h4>
                <p>{t('fees.subscriptions.agree')}</p>
                <p>{t('fees.subscriptions.notify')}</p>
                <p>{t('fees.subscriptions.cancel')}</p>

                <h2>
                    <li>{t('copyright.title')}</li>
                </h2>
                <p>{t('copyright.description')}</p>

                <h2>
                    <li>{t('ip.title')}</li>
                </h2>
                <p>{t('ip.description')}</p>

                <h2>
                    <li>{t('license.title')}</li>
                </h2>
                <h4>{t('license.subtitle')}</h4>
                <p>{t('license.description')}</p>
                <ol type="A">
                    <p>
                        <li>{t('license.comply')}</li>
                    </p>
                    <p>
                        <li>{t('license.copy')}</li>
                    </p>
                    <p>
                        <li>{t('license.install')}</li>
                    </p>
                    <p>
                        <li>{t('license.expiration')}</li>
                    </p>
                    <p>
                        <li>{t('license.measures')}</li>
                    </p>
                </ol>

                <h2>
                    <li>{t('changes.title')}</li>
                </h2>
                <p>{t('changes.description')}</p>

                <h2>
                    <li>{t('data.title')}</li>
                </h2>
                <p>{t('data.description')}</p>

                <h2>
                    <li>{t('warranties.title')}</li>
                </h2>
                <p className="bold">{t('warranties.asIs')}</p>
                <p className="bold">{t('warranties.officers')}</p>
                <ol type="A">
                    <p className="bold">
                        <li>{t('warranties.true')}</li>
                    </p>
                    <p className="bold">
                        <li>{t('warranties.meet')}</li>
                    </p>
                    <p className="bold">
                        <li>{t('warranties.error')}</li>
                    </p>
                    <p className="bold">
                        <li>{t('warranties.reliable')}</li>
                    </p>
                    <p className="bold">
                        <li>{t('warranties.defects')}</li>
                    </p>
                </ol>

                <h2>
                    <li>{t('liability.title')}</li>
                </h2>
                <p className="bold">{t('liability.description')}</p>
                <ol type="A">
                    <p className="bold">
                        <li>{t('liability.direct.description')}</li>
                    </p>
                    <ol type="a">
                        <p className="bold">
                            <li>{t('liability.direct.loss')}</li>
                        </p>
                        <p className="bold">
                            <li>{t('liability.direct.reliance')}</li>
                        </p>
                        <p className="bold">
                            <li>{t('liability.direct.changes')}</li>
                        </p>
                        <p className="bold">
                            <li>{t('liability.direct.accurate')}</li>
                        </p>
                        <p className="bold">
                            <li>{t('liability.direct.secure')}</li>
                        </p>
                    </ol>
                    <p className="bold">
                        <li>{t('liability.injury')}</li>
                    </p>
                    <p className="bold">
                        <li>{t('liability.not')}</li>
                    </p>
                </ol>

                <h2>
                    <li>{t('indemnification.title')}</li>
                </h2>
                <p>{t('indemnification.description')}</p>

                <h2>
                    <li>{t('dispute.title')}</li>
                </h2>
                <p>{t('dispute.constitutes')}</p>
                <p>{t('dispute.invalid')}</p>
                <h3>{t('dispute.arbitration.title')}</h3>
                <h4>{t('dispute.arbitration.dispute')}</h4>
                <p>
                    <span>{t('dispute.arbitration.agree')}</span>
                    <span className="bold"> {t('dispute.arbitration.understand')} </span>
                    <span>{t('dispute.arbitration.court')}</span>
                </p>
                <p>{t('dispute.arbitration.claims')}</p>
                <h4>{t('dispute.arbitration.notice')}</h4>
                <p>{t('dispute.arbitration.party')}</p>
                <p>{t('dispute.arbitration.nature')}</p>
                <h4>{t('dispute.arbitration.enforceability')}</h4>
                <p>{t('dispute.arbitration.invalid')}</p>

                <h2>
                    <li>{t('miscellaneous.title')}</li>
                </h2>
                <h4>{t('miscellaneous.compliance.title')}</h4>
                <p>{t('miscellaneous.compliance.description')}</p>
                <h4>{t('miscellaneous.headings.title')}</h4>
                <p>{t('miscellaneous.headings.description')}</p>
                <h4>{t('miscellaneous.agreement.title')}</h4>
                <p>{t('miscellaneous.agreement.description')}</p>
                <h4>{t('miscellaneous.law.title')}</h4>
                <p>{t('miscellaneous.law.description')}</p>
                <h4>{t('miscellaneous.severability.title')}</h4>
                <p>{t('miscellaneous.severability.description')}</p>
                <h4>{t('miscellaneous.waiver.title')}</h4>
                <p>{t('miscellaneous.waiver.description')}</p>
                <h4>{t('miscellaneous.assignment.title')}</h4>
                <p>{t('miscellaneous.assignment.description')}</p>
                <h4>{t('miscellaneous.termination.title')}</h4>
                <p>{t('miscellaneous.termination.description')}</p>
                <h4>{t('miscellaneous.relationship.title')}</h4>
                <p>{t('miscellaneous.relationship.description')}</p>
                <h4>{t('miscellaneous.force.title')}</h4>
                <p>{t('miscellaneous.force.description')}</p>

                <h2>
                    <li>{t('contact.title')}</li>
                </h2>
                <p>{t('contact.description')}</p>
            </ol>
        </div>
    )
}

export default TermsOfUse
