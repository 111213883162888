import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import TermsOfUse from '../components/TermsOfUse'
import PrivacyStatement from '../components/PrivacyStatement'

const TermsAndPrivacy = () => (
    <Layout>
        <SEO title="terms:title" />
        <TermsOfUse />
        <PrivacyStatement />
    </Layout>
)

export default TermsAndPrivacy
