import React from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyStatement = () => {
    const { t } = useTranslation('privacy')
    return (
        <div className="privacyPage">
            <h1 className="pageTitle">{t('title')}</h1>
            <p>
                <span className="bold">{t('valuesYourPrivacy')} </span>
                <span>{t('learnMore')}</span>
            </p>
            <p className="bold">{t('lastUpdated')}</p>
            <p className="italic">{t('changes')}</p>
            <ol>
                <h2>
                    <li>{t('cover.title')}</li>
                </h2>
                <p>{t('cover.statement')}</p>
                <p>{t('cover.note')}</p>

                <h2>
                    <li>{t('personal.title')}</li>
                </h2>
                <p>{t('personal.description')}</p>
                <ul>
                    <p>
                        <li>
                            <span className="bold">{t('personal.personalData.title')}: </span>
                            <span>{t('personal.personalData.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.userData.title')}: </span>
                            <span>{t('personal.userData.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.locationData.title')}: </span>
                            <span>{t('personal.locationData.description')}</span>
                        </li>
                    </p>
                </ul>
                <p>{t('personal.uses')}</p>
                <ul>
                    <p>
                        <li>
                            <span className="bold">{t('personal.website.title')}: </span>
                            <span>{t('personal.website.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.sales.title')}: </span>
                            <span>{t('personal.sales.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.marketing.title')}: </span>
                            <span>{t('personal.marketing.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.registration.title')}: </span>
                            <span>{t('personal.registration.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.customerService.title')}: </span>
                            <span>{t('personal.customerService.description')}</span>
                        </li>
                    </p>
                    <p>
                        <li>
                            <span className="bold">{t('personal.job.title')}: </span>
                            <span>{t('personal.job.description')}</span>
                        </li>
                    </p>
                </ul>
                <p>{t('personal.followingTable')}</p>
                <table>
                    <thead>
                        <tr>
                            <th>{t('personal.table.data')}</th>
                            <th>{t('personal.table.purpose')}</th>
                            <th>{t('personal.table.thirdParty')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('personal.table.name')}</td>
                            <td>{t('personal.table.namePurpose')}</td>
                            <td>{t('personal.table.nameThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.contact')}</td>
                            <td>{t('personal.table.contactPurpose')}</td>
                            <td>{t('personal.table.contactThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.payment')}</td>
                            <td>{t('personal.table.paymentPurpose')}</td>
                            <td>{t('personal.table.paymentThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.username')}</td>
                            <td>{t('personal.table.usernamePurpose')}</td>
                            <td>{t('personal.table.usernameThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.birthday')}</td>
                            <td>{t('personal.table.birthdayPurpose')}</td>
                            <td>{t('personal.table.birthdayThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.answer')}</td>
                            <td>{t('personal.table.answerPurpose')}</td>
                            <td>{t('personal.table.answerThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.ip')}</td>
                            <td>{t('personal.table.ipPurpose')}</td>
                            <td>{t('personal.table.ipThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.social')}</td>
                            <td>{t('personal.table.socialPurpose')}</td>
                            <td>{t('personal.table.socialThirdParty')}</td>
                        </tr>
                        <tr>
                            <td>{t('personal.table.employment')}</td>
                            <td>{t('personal.table.employmentPurpose')}</td>
                            <td>{t('personal.table.employmentThirdParty')}</td>
                        </tr>
                    </tbody>
                </table>

                <h2>
                    <li>{t('cookies.title')}</li>
                </h2>
                <p>{t('cookies.definition')}</p>
                <p>{t('cookies.store')}</p>
                <p>{t('cookies.serve')}</p>
                <p>{t('cookies.use.description')}</p>
                <ul>
                    <p>
                        <li>{t('cookies.use.notUse')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.use.notify')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.use.refuse')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.use.update')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.use.delete')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.use.browse')}</li>
                    </p>
                </ul>
                <p>{t('cookies.function')}</p>
                <p>{t('cookies.control')}</p>
                <p>{t('cookies.optOut')}</p>
                <ul>
                    <p>
                        <li>{t('cookies.google')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.facebook')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.twitter')}</li>
                    </p>
                    <p>
                        <li>{t('cookies.adobe')}</li>
                    </p>
                </ul>
                <p>{t('cookies.additional')}</p>

                <h2>
                    <li>{t('children.title')}</li>
                </h2>
                <p>{t('children.description')}</p>

                <h2>
                    <li>{t('howLong.title')}</li>
                </h2>
                <p>{t('howLong.description')}</p>
                <ul>
                    <p>
                        <li>{t('howLong.account')}</li>
                    </p>
                    <p>
                        <li>{t('howLong.provide')}</li>
                    </p>
                    <p>
                        <li>{t('howLong.comply')}</li>
                    </p>
                    <p>
                        <li>{t('howLong.enforce')}</li>
                    </p>
                </ul>

                <h2>
                    <li>{t('sell.title')}</li>
                </h2>
                <p>{t('sell.description')}</p>
                <p>{t('sell.inAddition')}</p>

                <h2>
                    <li>{t('transfer.title')}</li>
                </h2>
                <p>{t('transfer.mayCollect')}</p>
                <p>{t('transfer.ensure')}</p>
                <p>{t('transfer.european')}</p>

                <h2>
                    <li>{t('protect.title')}</li>
                </h2>
                <p>{t('protect.description')}</p>
                <p>{t('protect.however')}</p>

                <h2>
                    <li>{t('review.title')}</li>
                </h2>
                <p>{t('review.submit')}</p>
                <p>{t('review.review')}</p>
                <p>{t('review.agent')}</p>
                <p>{t('review.questions')}</p>

                <h2>
                    <li>{t('contact.title')}</li>
                </h2>
                <p>{t('contact.description')}</p>
                <p>{t('contact.email')}</p>
            </ol>
        </div>
    )
}

export default PrivacyStatement
